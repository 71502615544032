import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout/layout';
import { H1, P } from '../components';
import Icon from '../components/icons/icon';
import { HopIcon } from '../components/icons/hop';
import { GlassIcon } from '../components/icons/glass';
import { WindowIcon } from '../components/icons/window';
import ContactForm from '../components/forms/contact';

export default function About() {
  return (
    <Layout title="About Us">
      <div className="w-full pt-32 md:pt-40 lg:pt-60">
        <section className="w-full flex flex-col lg:flex-row justify-center lg:justify-between">
          <div className="w-1/2 lg:w-1/4 max-w-xl lg:mr-auto">
            <StaticImage
              placeholder="blurred"
              src="../assets/images/about/logo-half.png"
              alt="Half golden malt with Lokabrews Works Text"
              layout="fullWidth"
            />
          </div>
          <div className="mobile:mx-10 mobile:mt-10 lg:mr-auto">
            <H1>About Us</H1>
            <P>
              Through <b>science</b>, we journey into the depths of human existence. We acknowledge and use the
              techniques learned over the years.
            </P>
            <P>
              Through <b>culture</b>, we develop cus- toms and traditions that help us tell our stories.
            </P>
            <P>
              Through <b>community</b>, we respect our heritage and share different ways of living.
            </P>
          </div>
        </section>
        <div className="w-full my-10 lg:my-32 flex justify-center items-center">
          <div className="w-32 lg:w-40">
            <StaticImage
              placeholder="blurred"
              src="../assets/images/about/cheers.png"
              alt="To hands holding beer jugs cheers each other"
              layout="fullWidth"
            />
          </div>
        </div>
        <section className="px-10 gap-10 w-full flex flex-col justify-center text-center items-center">
          <P>
            LOKA BREW WORKS are unique experiences that unite us as humans. It is a cultural exchange that winds us to
            learn about other civilizations through a beer made for a connected community.
          </P>
          <P>
            We believe that learning and experiencing new cultures and customs enriches everyone&apos;s life. We want to
            share the real tasting notes, colors, and ingredients in our proposal of artisanal Belgian beers.
          </P>
          <P>
            Our main drive is constant curiosity. Loka Brew Works is the messenger that connects people and traditions.
            The one entrusted with respecting, preserving and sharing the worldview of different civilizations.
            It&apos;s the carrier that holds the legacy created by the union of cultures.
          </P>
        </section>
        <div className="w-full my-10 lg:my-32 flex justify-center items-center">
          <Icon className="w-20 lg:w-32" title="Golden hop">
            <HopIcon />
          </Icon>
        </div>
        <section className="px-10 gap-10 w-full flex flex-col lg:flex-row justify-center lg:justify-around">
          <div>
            <H1>HISTORY AND TRADITION</H1>
            <P>
              We work from history. We recognize and share the context behind each of our beer selections. Loka Brew
              Works is not only the au- thentic experience of the styles and tasting notes of our beer propo- sal, but
              also the stories that have made possible the cultural product we enjoy today.
            </P>
            <Icon className="block lg:hidden w-1/2 lg:w-1/4 max-w-xl m-auto my-10" title="Window shapes">
              <WindowIcon />
            </Icon>
            <P>
              We preserve the techniques and care for the heritage of each recipe. By staying true to the ingredients
              and the science that helps us make our brew works, we secure the traditions born hundreds of years ago.
              Loka Brew Works exists thanks to the legacy of artisanal Belgian beers.
            </P>
          </div>
          <Icon className="hidden lg:block w-1/2 lg:w-1/4 max-w-xl" title="Window shapes">
            <WindowIcon />
          </Icon>
        </section>
        <div className="w-full my-10 lg:my-32 flex justify-center items-center">
          <Icon className="w-10 lg:w-16" title="A copa glass">
            <GlassIcon />
          </Icon>
        </div>
        <ContactForm />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
